export default class RestApiCache {
    options: {
        cacheDuration: number;
        urlBase?: string;
    }

    cache: {
        [url: string]: {
          data: any;
          timestamp: number;
        };
    } = {}

    constructor(options: Partial<typeof RestApiCache.prototype.options>) {
        this.options = {
            cacheDuration: 1000 * 60 * 5, // 5 minutes
            ...options
        };
    }
    
    async get(path: string, options?: RequestInit): Promise<any> {
        const url = this.options.urlBase ? `${this.options.urlBase}${path}` : path;
        const cacheTimeLimit = Date.now() - this.options.cacheDuration;
        const cachedData = this.cache[url];
        const cachedDataIsFresh = cachedData && cachedData.timestamp > cacheTimeLimit;

        if (cachedDataIsFresh) return this.cache[url].data;

        const response = await fetch(url, options);
        const data = await response.json();

        this.set(url, data);
        
        return data;
    }
    
    set(url: string, data: any): void {
        this.cache[url] = {
            data,
            timestamp: Date.now()
        };
    }
}