import RestApiCache from "./RestApiCache";
import DatePicker from "./DatePicker";

class DataTable {
  private static selectors = {
    app: "[data-hdt]",
    table: "[data-hdt-table]",
    pagination: "[data-hdt-pagination]",
    paginationNext: "[data-hdt-pagination-next]",
    paginationPrev: "[data-hdt-pagination-prev]",
    paginationItem: "[data-hdt-pagination-item]",
    resetButton: "[data-hdt-reset]",
    loader: "[data-hdt-loader]",
    error: "[data-hdt-error]",
  }

  public static init() {
      const tables = document.querySelectorAll(DataTable.selectors.app);

      for (const table of tables) {
          if (table instanceof HTMLElement) {
              new DataTable(table);
          }
      }
  }

  private id: string;
  private cache: RestApiCache;
  private el: {
    main: HTMLElement | null;
    pagination: HTMLElement | null
    pageNext: HTMLElement | null
    pagePrev: HTMLElement | null
    reset: HTMLButtonElement | null
  };
  private date: {
    column: string | undefined;
    startDate: DatePicker | undefined;
    endDate: DatePicker | undefined;
  } | null = null;
  private state: {
    page: number;
    per_page: number;
    sort: string | undefined;
    order: 'ASC' | 'DESC' | undefined;
  }

  private constructor(element: HTMLElement) {
      if (!element.dataset.hdt) {
        throw new Error('DataTable element must have a data-hdt attribute value');
      }
      this.cache = new RestApiCache({ urlBase: `/wp-json/hyphen-data-table/v1/results` });
      this.id = element.dataset.hdt;
      this.el = {
        main: element,
        pagination: element.querySelector<HTMLElement>(DataTable.selectors.pagination),
        pageNext: element.querySelector<HTMLElement>(DataTable.selectors.paginationNext),
        pagePrev: element.querySelector<HTMLElement>(DataTable.selectors.paginationPrev),
        reset: element.querySelector<HTMLButtonElement>(DataTable.selectors.resetButton),
      }
      this.state = {
        page: 1,
        per_page: Number(element.dataset.hdtPerPage) ?? 10,
        sort: element.dataset.hdtSort,
        order: element.dataset.hdtOrder === 'ASC' ? 'ASC' : 'DESC',
      }
      try {
        this.initDates();
      } catch (error) {
        console.error(error)
      }
      this.addEventListeners();
      this.update({first: true});
  }

  private addEventListeners() {
      if (this.el.pagination) {
          this.el.pageNext = this.el.pagination.querySelector<HTMLElement>(DataTable.selectors.paginationNext);
          this.el.pagePrev = this.el.pagination.querySelector<HTMLElement>(DataTable.selectors.paginationPrev);

          if (this.el.pageNext) this.el.pageNext.addEventListener('click', this.handleNextClick.bind(this));
          if (this.el.pagePrev) this.el.pagePrev.addEventListener('click', this.handlePrevClick.bind(this));
      }

      if (this.el.reset) {
        this.el.reset.value
        this.el.reset.addEventListener('click', this.reset.bind(this));
      }
  }

  private async handleNextClick() {
      this.state.page++;
      await this.update();
  }

  private async handlePrevClick() {
      this.state.page--;
      await this.update();
  }

  private getParams() {
    const params = new URLSearchParams();

    params.set('page', String(this.state.page));
    params.set('per_page', String(this.state.per_page));
    if (this.state.sort) params.set('sort', this.state.sort);
    if (this.state.order) params.set('order', this.state.order);
    if (this.date?.column) {
      const startDate = this.date.startDate?.getDate();
      const endDate = this.date.endDate?.getDate();
      params.set('date_column', this.date.column);
      if (startDate) params.set('start_date', startDate.toISOString());
      if (endDate) {
        const end = new Date(endDate);
        end.setMonth(end.getMonth() + 1);
        params.set('end_date', end.toISOString());
      }
    }

    params.set('file', this.id);

    return '?' + params.toString();
  }

  private initDates() {
    const el = this.el.main;
    if (el && el.dataset.hdtDateCol) {
      const startEl = el.querySelector<HTMLElement>(`[data-hdt-date-start]`);
      const endEl = el.querySelector<HTMLElement>(`[data-hdt-date-end]`);
      
      if (!startEl) throw new Error('DataTable element must have a data-hdt-date-start attribute value');
      if (!endEl) throw new Error('DataTable element must have a data-hdt-date-end attribute value');

      this.date = {
        column: el.dataset.hdtDateCol,
        startDate: new DatePicker(startEl, {
          startDate: new Date(this.el.main?.dataset.hdtFirstDate ?? ''),
          endDate: new Date(this.el.main?.dataset.hdtLastDate ?? ''),
        }),
        endDate: new DatePicker(endEl, {
          startDate: new Date(this.el.main?.dataset.hdtFirstDate ?? ''),
          endDate: new Date(this.el.main?.dataset.hdtLastDate ?? ''),
        }),
      }

      this.date.startDate?.onChange(this.handleDateChange.bind(this));
      this.date.endDate?.onChange(this.handleDateChange.bind(this));
    }
  }

  private handleDateChange(_date: Date | null) {
    if (this.date?.column) {
      this.state.page = 1;
      if (this.date.startDate?.getDate() || this.date.endDate?.getDate()) {
        this.el.reset?.removeAttribute('disabled');
        this.el.reset?.removeAttribute('aria-disabled');
        this.el.reset?.focus();
      }
      if (this.date.startDate?.getDate()) {
        this.date.endDate?.enable();
      } else {
        this.date.endDate?.disable();
        this.el.reset?.setAttribute('disabled', 'true');
        this.el.reset?.setAttribute('aria-disabled', 'true');
      }
      this.update();
    }
  }

  private async update({ first }: { first?: boolean } = { first: false }) {
    if (!first) this.startLoading();
    try {
      const { results, total } = await this.cache.get(this.getParams());
      this.stopLoading();
      
      if (results) {
        if (results.length) {
          this.updateHead(Object.keys(results[0]));
        }
        this.updateBody(results);
        this.updatePagination(this.state.page, total);
      }

      if (this.date && (this.date.startDate?.getDate() || this.date.endDate?.getDate())) {
        this.el.reset?.removeAttribute('disabled');
        this.el.reset?.removeAttribute('aria-disabled');
      }

    } catch (error) {
      this.stopLoading();
      this.handleError(error instanceof Error ? error : new Error('Failed to fetch data'));
    }
  }

  private updateHead(headers: string[]) {
    const head = this.el.main?.querySelector<HTMLElement>('thead');

    if (head) {
      const headTableRow = head.querySelector<HTMLElement>('tr') ?? document.createElement('tr');
      headTableRow.innerHTML = '';
      head.appendChild(headTableRow);
      
      for (const cell of headers) {
        const cellElement = document.createElement('th');
        cellElement.innerText = cell;
        headTableRow.appendChild(cellElement);
      }
    }
  }

  private updateBody(results: any[]) {
    const body = this.el.main?.querySelector<HTMLElement>('tbody');

    if (body) {
      body.innerHTML = '';

      for (const row of results) {
        const rowElement = document.createElement('tr');

        for (const value of Object.values<string>(row)) {
          const cellElement = document.createElement('td');
          rowElement.appendChild(cellElement);
          cellElement.innerText = value;
        }
        
        body.appendChild(rowElement);
      }
    }
  }

  private updatePagination(page: number, total: number) {
    if (this.el.pagination) {
      this.el.pagePrev?.remove();
      this.el.pageNext?.remove();

      this.el.pagination.innerHTML = '';
      if (this.el.pagePrev) this.el.pagination.appendChild(this.el.pagePrev);
      this.updatePaginationItems(page, total);
      if (this.el.pageNext) this.el.pagination.appendChild(this.el.pageNext);
    }

    if (this.el.pageNext) {
      if (this.state.page * this.state.per_page < total) {
        this.el.pageNext.classList.remove('disabled');
        this.el.pageNext.removeAttribute('disabled');
        this.el.pageNext.removeAttribute('aria-disabled');
      } else {
        this.el.pageNext.classList.add('disabled');
        this.el.pageNext.setAttribute('disabled', 'true');
        this.el.pageNext.setAttribute('aria-disabled', 'true');
      }
    }

    if (this.el.pagePrev) {
      if (this.state.page > 1) {
        this.el.pagePrev.classList.remove('disabled');
        this.el.pagePrev.removeAttribute('disabled');
        this.el.pagePrev.removeAttribute('aria-disabled');
      } else {
        this.el.pagePrev.classList.add('disabled');
        this.el.pagePrev.setAttribute('disabled', 'true');
        this.el.pagePrev.setAttribute('aria-disabled', 'true');
      }
    }
  }

  private updatePaginationItems(page: number, total: number) {
    // if (!this.el.pagination) return;

    const totalPages = Math.ceil(total / this.state.per_page);

    const pageItem = (page: number, current: number) => {
      if (!this.el.pagination) return;
      
      const button = document.createElement('button');
      button.type = 'button';
      button.dataset.hdtPaginationItem = String(page);
      button.innerText = String(page);
      if (page === current) button.classList.add('current');
      button.addEventListener('click', () => {
        this.state.page = page;
        this.update();
      });
      this.el.pagination.appendChild(button);
    }

    const abbr = () => {
      if (!this.el.pagination) return;
      const span = document.createElement('span');
      span.dataset.hdtPaginationAbbr = 'true';
      span.innerHTML = '&hellip;';
      this.el.pagination.appendChild(span);
    }

    if (page < 5) {
      //       < 5
      // 1 2 3 4 5 . 10
      for (let i = 1; i < totalPages && i < 6; i++) {
        pageItem(i, page);
      }
      if (totalPages > 5) abbr();
      pageItem(totalPages, page);
    } else if (page > totalPages - 4) {
      //     4 3 2 1 total
      // 1 . 6 7 8 9 10
      if (totalPages > 5) {
        pageItem(1, page);
        abbr();
      }
      for (let i = totalPages - 4; i <= totalPages; i++) {
        pageItem(i, page);
      }
    } else {
      // 1 . 4 5 6 . 10
      pageItem(1, page);
      abbr();
      for (let i = page - 1; i <= page + 1; i++) {
        pageItem(i, page);
      }
      abbr();
      pageItem(totalPages, page);
    }  
    
  }

  private handleError(error: Error) {
    const errorElement = this.el.main?.querySelector<HTMLElement>(DataTable.selectors.error);
    if (errorElement) {
      errorElement.innerText = error.message;
      errorElement.classList.remove('hidden');
    }
  }

  private startLoading() {
    const loader = this.el.main?.querySelector<HTMLElement>(DataTable.selectors.loader);
    if (loader) loader.classList.add('loading');
    const errorElement = this.el.main?.querySelector<HTMLElement>(DataTable.selectors.error);
    if (errorElement) errorElement.classList.add('hidden');
  }

  private stopLoading() {
    const loader = this.el.main?.querySelector<HTMLElement>(DataTable.selectors.loader);
    if (loader) loader.classList.remove('loading');
  }

  private reset() {
    this.state.page = 1;
    this.state.order = this.el.main?.dataset.hdtOrder === 'ASC' ? 'ASC' : 'DESC';
    this.state.per_page = Number(this.el.main?.dataset.hdtPerPage) ?? 10;
    this.state.sort = this.el.main?.dataset.hdtSort;
    this.date?.startDate?.reset();
    this.date?.endDate?.reset();
    this.date?.endDate?.disable();
    this.el.reset?.setAttribute('disabled', 'true');
    this.el.reset?.setAttribute('aria-disabled', 'true');
    this.update();
  }
}

export default DataTable