/**
 * @package Hyphen_Data_Table
 * @since 1.0.0
 * @version 1.0.0
 * 
 * @description This file is the entry point for the app. It includes the main
 * app file and the main stylesheet. It also enables hot module replacement for
 * the app during development.
 */

import "./css/index.scss";
import DataTable from './src/DataTable'

// Load the app once the page is ready
window.addEventListener("DOMContentLoaded", DataTable.init);

// Enable hot module replacement during development
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		window.location.reload();
	});
}