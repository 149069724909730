interface DatePickerOptions {
  selectors?: Partial<DatePicker['selectors']>;
  startDate?: Date;
  endDate?: Date;
}

class DatePicker {
  private selectors = {
    month: '[data-hdt-date="month"]',
    year: '[data-hdt-date="year"]',
  }

  private el: {
    main: HTMLElement;
    month: HTMLSelectElement | null;
    year: HTMLSelectElement | null;
  }

  constructor(element: HTMLElement, options?: DatePickerOptions) {
    if (options?.selectors) this.selectors = { ...this.selectors, ...options.selectors };

    this.el = {
      main: element,
      month: element.querySelector<HTMLSelectElement>(this.selectors.month),
      year: element.querySelector<HTMLSelectElement>(this.selectors.year),
    }

    if (options?.startDate && options?.endDate) {
      this.addOptions(options.startDate, options.endDate);
    }
  }

  private addOptions(start: Date, end: Date) {
    if (this.el.month) {
      for (let i = 0; i < 12; i++) {
        const option = document.createElement('option');
        option.value = String(i + 1);
        const formatter = new Intl.DateTimeFormat('default', { month: 'long' });
        option.innerText = formatter.format(new Date(0, i));
        this.el.month.appendChild(option);
      }
    }

    if (this.el.year) {
      for (let i = start.getFullYear(); i <= end.getFullYear(); i++) {
        const option = document.createElement('option');
        option.value = String(i);
        option.innerText = String(i);
        this.el.year.appendChild(option);
      }
    }
  }

  public getDate(): Date | null {
    if (this.el.month && this.el.year) {
      const month = this.el.month.value;
      const year = this.el.year.value;
      if (month && year) {
        return new Date(Number(year), Number(month) - 1);
      }
    }
    return null;
  }

  public onChange(callback: (date: Date | null) => void) {
    if (this.el.month && this.el.year) {
      this.el.month.addEventListener('change', () => {
        const date = this.getDate();
        callback(date);
      });
      this.el.year.addEventListener('change', () => {
        const date = this.getDate();
        callback(date);
      });
    }
  }

  public reset() {
    if (this.el.month) {
      this.el.month.value = '';
    }
    if (this.el.year) {
      this.el.year.value = '';
    }
  }

  public disable() {
    this.el.main.classList.add('disabled');
    this.el.main.setAttribute('aria-disabled', 'true')
    if (this.el.month) {
      this.el.month.classList.add('disabled');
      this.el.month.disabled = true;
    }
    if (this.el.year) {
      this.el.year.classList.add('disabled');
      this.el.year.disabled = true;
    }
  }

  public enable() {
    this.el.main.classList.remove('disabled');
    this.el.main.removeAttribute('aria-disabled');
    if (this.el.month) {
      this.el.month.classList.remove('disabled');
      this.el.month.disabled = false;
    }
    if (this.el.year) {
      this.el.year.classList.remove('disabled');
      this.el.year.disabled = false;
    }
  }
}

export default DatePicker;